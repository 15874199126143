import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import InventoryAnalyticsPage from './DInventoryAnalyticsPage';
import UpdateInventoryPage from './DUpdateInventoryPage';


export default function DIMMainPage() {

    const { setCurrModule, allowedFunc, token } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['company_parser:update_company_data'])) {
                    setCurrModule(configs.modules.depository)
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='rmContainer' className="rm-container">
                    <InventoryAnalyticsPage />
                    {allowedFunc.includes(configs.functionList['company_parser:update_company_data']) ?
                        <UpdateInventoryPage />
                        : null}
                    <ToastPage />
                </div>
            </div>
        </>
    )
}