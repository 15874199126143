import axios from "axios";
import { configs } from "../../../assets/Config";

// get order list by order type
export function getOrderListByType(token, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/orders/${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all orders
export function getAllOrders(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/allOrders`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// save utr details 
export function saveUTRByOrderId(token, orderId, utrList) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "utr": utrList
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/manualPayment`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// complete demat transfer
export function completeDematTransferByOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/dematTransferComplete`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update payment pending order status
export function updatePayPendingStatusByOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark,
                "status": "rejected"
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/expireOrRejectGeneratedPayment`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    }
    )
}

// update payment pending order status
export function getOrderDetail(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/order/${orderId}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update payment pending order status
export function acknowledgeManualPaymentByOrder(token, orderId, remark, customerRemark, status, utrList, dateTime) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark,
                "customer_remark": customerRemark,
                "status": status,
                "utr": utrList,
                "datetime": dateTime
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/acknowledgeManualPayment`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// reject high value order 
export function rejectHighValueOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/rejectUserOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// refund high value order 
export function refundHighValueOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/initiateRefund`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// complete refund high value order 
export function completeRefundHighValueOrder(token, orderId, remark) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "remark": remark
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/completeRefund`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// apprve high value order
export function approveHighValueOrder(token, orderId, quantity, price, update) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "quantity": quantity,
                "price_per_share": price
            }
            let urlM = update ? "updateGeneratedOrders" : "approveInterventionNeededOrder"
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/${urlM}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all companies
export function getAllCompanies(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/companies`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get company slabs
export function getCompanySlabs(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/company/${companyId}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// manual order punch by customer id
export function manualOrderPunchCustomerId(token, userId, companyId, price, quantity, distributorId, upmark, upmark_final_pay) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "user_id": userId,
                "company_id": companyId,
                "quantity": quantity,
                "price_per_share": price,
                "upmark": upmark,
                "upmark_final_pay": upmark_final_pay,
                "distributor_id": distributorId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/manualOrderPunching`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// delete order by id
export function deleteOrder(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/deleteOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore deleted order by id
export function restoreOrder(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/restoreOrder`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// download invoice
export function downloadInvoice(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/downloadInvoice/${orderId}`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get SPD sheet
export function downloadSPDSheet(token, orderIds) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/spdDownload`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    order_ids: orderIds
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get HDFC sheet
export function downloadHDFCSheet(token, orderIds) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/downloadRefundHdfcSheet`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    order_ids: orderIds
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// download social media image
export function downloadSocialMediaImage(token, companyId, date, title, description) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                company_ids: companyId,
                date: date,
                heading: title,
                sub_heading: description
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/socialMedia/download`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update buy order comment
export function buyOrderComment(token, orderId, comment) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "order_id": orderId,
                "comment": comment
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/comment`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// edit mandate
export function upsertMandateRequest(token, userId, companyId, price, quantity, percentage, comment, remark, mandateId, status) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = mandateId ?
                {
                    "id": mandateId,
                    "quantity": quantity,
                    "price_per_share": price,
                    "advance_percentage": percentage,
                    "comment": comment,
                    "remark": remark,
                    "status": status
                } : {
                    "user_id": userId,
                    "company_id": companyId,
                    "quantity": quantity,
                    "price_per_share": price,
                    "advance_percentage": percentage,
                    "comment": comment,
                    "remark": remark
                }
            var config = {
                method: mandateId ? 'patch' : 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/mandate`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// get mandate
export function getAllMandates(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/mandate`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// inventory tracker
export function inventoryTracker(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/spd-tracking-sheet`,
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// cc avenue transaction history
export function getCCAvenueHistory(token, orderId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/buy/transactions/ccavenue/${orderId}`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}