import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AdminLoginPage from './components/pages/login/AdminLoginPage';
import { LoginProvider } from './services/contexts/AdminLoginContext';
import LoginPrivateRoute from './services/privateRoutes/LoginPrivateRoute';
import { KYCProvider } from './services/contexts/KYCContext';
import RMMainPage from './components/pages/roleManagement/RMMainPage';
import KYCMainPage from './components/pages/KYC/KYCMainpage';
import { RMProvider } from './services/contexts/RMContext';
import AMMainPage from './components/pages/roleManagement/AMMainPage';
import NMPage from './components/pages/notificationManagement/NMPage';
import { NotificationProvider } from './services/contexts/NMContext';
import NMTPage from './components/pages/notificationManagement/NMTPage';
import EmailTemplatePage from './components/pages/notificationManagement/EmailTemplatePage';
import MobileTemplatePage from './components/pages/notificationManagement/MobileTemplatePage';
import BellTemplatePage from './components/pages/notificationManagement/BellTemplatePage';
import InventoryPage from './components/pages/shareManagement/InventoryPage';
import BuyOrderPage from './components/pages/shareManagement/BuyOrdersPage';
import { BuyOrderProvider } from './services/contexts/BuyOrdersContext';
import ManualPunchPage from './components/pages/shareManagement/ManualPunchPage';
import CustomerProfilePage from './components/pages/CustomerDetail/CustomerProfilePage';
import { getAdminFunctions } from './services/apis/AdminLoginApi';
import { configs } from './assets/Config'
import CustomerStatPage from './components/pages/KYC/CustomerStatsPage';
import SellOrderPage from './components/pages/shareManagement/SellOrdersPage';
import { SellOrderProvider } from './services/contexts/SellOrdersContext';
import GenerateImagePage from './components/pages/miscellaneous/GenerateImagePage';
import RemarkMasterPage from './components/pages/miscellaneous/RemarkMasterPage';
import ReferralMasterPage from './components/pages/miscellaneous/ReferralManagement';
import CMMainPage from './components/pages/companyManagement/CMMainPage';
import { CMProvider } from './services/contexts/companyManagement/CMContext';
import { NewsProvider } from './services/contexts/companyManagement/NewsContext';
import NewsMainPage from './components/pages/newsManagement/NewsMainPage';
import CustomerDashboardPage from './components/pages/miscellaneous/CustomerDashboardPage';
import DistributorMainPage from './components/pages/Distributor/DistributorMainpage';
import { DistributorProvider } from './services/contexts/DistributorContext';
import DistributorProfilePage from './components/pages/Distributor/DistributorProfilePage';
import DistributorReviewLeadsPage from './components/pages/Distributor/DistributorReviewLeadsPage';
import CustomerReviewProLeadPage from './components/pages/KYC/CustomerReviewProLeadPage';
import CustomerProPaymentPage from './components/pages/KYC/CustomerProPaymentPage';
import { MLDProvider } from './services/contexts/assetManagement/MLDContext';
import { BondProvider } from './services/contexts/assetManagement/BondContext';
import MLDMainPage from './components/pages/assetMLDMgmt/MLDMainPage';
import BondMainPage from './components/pages/assetBondMgmt/BondMainPage';
import DistributorDashboardPage from './components/pages/miscellaneous/DistributorDashboardPage';
import DistributorStatsPage from './components/pages/Distributor/DistributorStatsPage';
import WhatsAppTemplatePage from './components/pages/notificationManagement/WhatsAppTemplatePage';
import GSecMainPage from './components/pages/assetGSecMgmt/GSecMainPage';
import { GSecProvider } from './services/contexts/assetManagement/GSecContext';
import WhatsAppBroadCastPage from './components/pages/notificationManagement/WhatsAppBroadcast';
import StartupMainPage from './components/pages/assetStartupMgmt/StartupMainPage';
import { StartupProvider } from './services/contexts/assetManagement/StartupContext';
import DistributorReferralMasterPage from './components/pages/miscellaneous/DistributorReferralManagement';
import { CouponProvider } from './services/contexts/couponManagement/CouponContext';
import CouponMainPage from './components/pages/couponMgmt/CouponMainPage';
import BucketMainPage from './components/pages/assetBucketMgmt/BucketMainPage';
import { BucketProvider } from './services/contexts/assetManagement/BucketContext';
import MixBucketMainPage from './components/pages/assetMixBucketMgmt/MixBucketMainPage';
import { MixBucketProvider } from './services/contexts/assetManagement/MixBucketContext';
import ProPlansPage from './components/pages/KYC/ProPlansPage';
import CustomerProReportPage from './components/pages/KYC/CustomerProReportPage';
import PromotionalMessagePage from './components/pages/miscellaneous/PromotionalMessagePage';
import CRMMainPage from './components/pages/crm/CRMMainPage';
import { CRMProvider } from './services/contexts/CRMContext';
import CustomerXclusivePaymentPage from './components/pages/KYC/CustomerXclusivePaymentPage';
import CustomerXclusiveLeadPage from './components/pages/KYC/CustomerXclusiveLeadPage';
import LendboxKYCPage from './components/pages/lendbox/LendboxKYCPage';
import LendboxInvestmentPage from './components/pages/lendbox/LendboxInvestmentPage';
import LendboxWithdrawPage from './components/pages/lendbox/LendboxWithdrawPage';
import LiquiloanKYCPage from './components/pages/liquiloans/LiquiloanKYCPage';
import LiquiloanInvestmentPage from './components/pages/liquiloans/LiquiloanInvestmentPage';
import LiquiloanWithdrawPage from './components/pages/liquiloans/LiquiloanWithdrawPage';
import LendboxCaseTrackingPage from './components/pages/lendbox/LendboxCaseTrackingPage';
import LiquiloanCaseTrackingPage from './components/pages/liquiloans/LiquiloanCaseTrackingPage';
import ResetPasswordPage from './components/includes/ResetPasswordPage';
import CRMNonUserMainPage from './components/pages/crm/NoUser/CRMMainPage';
import CRMOrderInsightMainPage from './components/pages/crm/OrderInsights/CRMMainPage';
import WhatsAppMessagePage from './components/pages/notificationManagement/WhatsAppMessagePage';
import CustomerHighlightManagementPage from './components/pages/miscellaneous/StatusManagement/CustomerHighlightManagementPage';
import IMMainPage from './components/pages/inventoryManagement/IMMainPage';
import CareerManagement from './components/pages/miscellaneous/CareerManagement';
import CustomerActionPage from './components/pages/KYC/CustomerActionPage';
import DistributorCommissionPage from './components/pages/Distributor/DistributorCommissionPage';
import DistributorPayoutPage from './components/pages/Distributor/DistributorPayoutPage';
import DistributorCommissionLedgerPage from './components/pages/Distributor/DistributorCommissionLedgerPage';
import MandateMainPage from './components/pages/mandateManagement/MandateMainPage';
import ManualInventoryPage from './components/pages/inventoryManagement/ManualInventoryPage';
import CCAvenueHistory from './components/pages/shareManagement/CCAvenueHistory';
import DIMMainPage from './components/pages/DPinventoryManagement/DIMMainPage';

(async () => {
  try {
    const { isUserLoggedIn, token: savedToken } = JSON.parse(localStorage?.getItem("alogin")) || {};
    if (isUserLoggedIn) {
      var response = await getAdminFunctions(savedToken)
      configs.testFunc = response.data
    }

    if ((response && response.status === 200 && response.data) || !isUserLoggedIn) {

      ReactDOM.render(
        <LoginProvider>
          <KYCProvider>
            <RMProvider>
              <CMProvider>
                <NewsProvider>
                  <NotificationProvider>
                    <BuyOrderProvider>
                      <SellOrderProvider>
                        <DistributorProvider>
                          <MLDProvider>
                            <BucketProvider>
                              <MixBucketProvider>
                                <BondProvider>
                                  <GSecProvider>
                                    <StartupProvider>
                                      <CouponProvider>
                                        <CRMProvider>
                                          <Router>
                                            <Switch>
                                              <Route exact path={["/login", "/"]}>
                                                <AdminLoginPage></AdminLoginPage>
                                              </Route>
                                              <Route exact path="/resetpassword">
                                                <ResetPasswordPage></ResetPasswordPage>
                                              </Route>
                                              <LoginPrivateRoute path="/kyc/:customerId?">
                                                <KYCMainPage></KYCMainPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/customerStatistics">
                                                <CustomerStatPage></CustomerStatPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/customerActions">
                                                <CustomerActionPage></CustomerActionPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/proLeads">
                                                <CustomerReviewProLeadPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/proPaymentDetail">
                                                <CustomerProPaymentPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/xclusiveLeads">
                                                <CustomerXclusiveLeadPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/xclusivePaymentDetail">
                                                <CustomerXclusivePaymentPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/proUserReport">
                                                <CustomerProReportPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/proPlans">
                                                <ProPlansPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/crm">
                                                <CRMMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/nonuserinsight">
                                                <CRMNonUserMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/orderinsight">
                                                <CRMOrderInsightMainPage />
                                              </LoginPrivateRoute>

                                              {/* --------------------distributor start-------------- */}
                                              <LoginPrivateRoute path="/distributor/:customerId?">
                                                <DistributorMainPage></DistributorMainPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorProfile/:customerId">
                                                <DistributorProfilePage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorFormLeads">
                                                <DistributorReviewLeadsPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorStatistics">
                                                <DistributorStatsPage></DistributorStatsPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorcommission">
                                                <DistributorCommissionPage></DistributorCommissionPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorcommissionledger">
                                                <DistributorCommissionLedgerPage></DistributorCommissionLedgerPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorpayout">
                                                <DistributorPayoutPage></DistributorPayoutPage>
                                              </LoginPrivateRoute>
                                              {/* --------------------distributor end-------------- */}

                                              {/* lendbox start */}
                                              <LoginPrivateRoute exact path="/lendbox/users">
                                                <LendboxKYCPage></LendboxKYCPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/lendbox/investment">
                                                <LendboxInvestmentPage></LendboxInvestmentPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/lendbox/withdraw">
                                                <LendboxWithdrawPage></LendboxWithdrawPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/lendbox/caseTracking">
                                                <LendboxCaseTrackingPage></LendboxCaseTrackingPage>
                                              </LoginPrivateRoute>
                                              {/* lendbox end */}

                                              {/* Liquiloan start */}
                                              <LoginPrivateRoute exact path="/liquiloan/users">
                                                <LiquiloanKYCPage></LiquiloanKYCPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/liquiloan/investment">
                                                <LiquiloanInvestmentPage></LiquiloanInvestmentPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/liquiloan/withdraw">
                                                <LiquiloanWithdrawPage></LiquiloanWithdrawPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/liquiloan/caseTracking">
                                                <LiquiloanCaseTrackingPage></LiquiloanCaseTrackingPage>
                                              </LoginPrivateRoute>
                                              {/* Liquiloan end */}

                                              {/* role roleManagement start */}
                                              <LoginPrivateRoute exact path="/roleManagement">
                                                <RMMainPage></RMMainPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/adminManagement">
                                                <AMMainPage></AMMainPage>
                                              </LoginPrivateRoute>
                                              {/* role roleManagement end */}

                                              {/* notification Management start */}
                                              <LoginPrivateRoute exact path="/notificationManagement">
                                                <NMPage></NMPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/notificationTemplateManagement">
                                                <NMTPage></NMTPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/emailTemplateManagement/:id">
                                                <EmailTemplatePage></EmailTemplatePage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/mobileTemplateManagement/:id">
                                                <MobileTemplatePage></MobileTemplatePage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/bellTemplateManagement/:id">
                                                <BellTemplatePage></BellTemplatePage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/whatsappTemplateManagement/:id">
                                                <WhatsAppTemplatePage></WhatsAppTemplatePage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/whatsappBroadcast">
                                                <WhatsAppBroadCastPage></WhatsAppBroadCastPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/whatsappMessages">
                                                <WhatsAppMessagePage></WhatsAppMessagePage>
                                              </LoginPrivateRoute>
                                              {/* notification Management end */}

                                              {/* share Management start */}
                                              <LoginPrivateRoute exact path="/shareManagement/inventory">
                                                <IMMainPage></IMMainPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/depository">
                                                <DIMMainPage></DIMMainPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/inventoryupdate">
                                                <ManualInventoryPage></ManualInventoryPage>
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/buyOrders">
                                                <BuyOrderPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/ccavenuehistory">
                                                <CCAvenueHistory />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/manualPunch">
                                                <ManualPunchPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/mandate">
                                                <MandateMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/shareManagement/sellOrders">
                                                <SellOrderPage />
                                              </LoginPrivateRoute>
                                              {/* share Management end */}

                                              {/* comapny management start */}
                                              <LoginPrivateRoute exact path="/companyManagement/companyDetail">
                                                <CMMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/companyManagement/newsDetail">
                                                <NewsMainPage />
                                              </LoginPrivateRoute>
                                              {/* comapny management end */}

                                              {/* asset management start */}
                                              <LoginPrivateRoute exact path="/assetManagement/mld">
                                                <MLDMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/assetManagement/bond">
                                                <BondMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/assetManagement/gsec">
                                                <GSecMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/assetManagement/startup">
                                                <StartupMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/assetManagement/bucket">
                                                <BucketMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/assetManagement/mixbucket">
                                                <MixBucketMainPage />
                                              </LoginPrivateRoute>
                                              {/* asset management end */}

                                              {/* user profile start */}
                                              <LoginPrivateRoute exact path="/customerProfile/:customerId">
                                                <CustomerProfilePage />
                                              </LoginPrivateRoute>
                                              {/* user profile end */}

                                              {/* miscellaneous management start */}
                                              <LoginPrivateRoute exact path="/couponManagemnt">
                                                <CouponMainPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/generateSocialMediaImage">
                                                <GenerateImagePage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/remarkManagemnt">
                                                <RemarkMasterPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/referralManagemnt">
                                                <ReferralMasterPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorReferralManagemnt">
                                                <DistributorReferralMasterPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/customerDashboardManagement">
                                                <CustomerDashboardPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/customerDashboardHighlightManagement">
                                                <CustomerHighlightManagementPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/distributorDashboardManagement">
                                                <DistributorDashboardPage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/promoManagement">
                                                <PromotionalMessagePage />
                                              </LoginPrivateRoute>
                                              <LoginPrivateRoute exact path="/careerManagemnt">
                                                <CareerManagement />
                                              </LoginPrivateRoute>
                                              {/* miscellaneous management end */}

                                              <Route component={() => (<div>404 Not found </div>)} />
                                            </Switch>
                                          </Router>
                                        </CRMProvider>
                                      </CouponProvider>
                                    </StartupProvider>
                                  </GSecProvider>
                                </BondProvider>
                              </MixBucketProvider>
                            </BucketProvider>
                          </MLDProvider>
                        </DistributorProvider>
                      </SellOrderProvider>
                    </BuyOrderProvider>
                  </NotificationProvider>
                </NewsProvider>
              </CMProvider>
            </RMProvider>
          </KYCProvider>
        </LoginProvider>,
        document.getElementById('root')
      );
    } else if (response && response.status === 401) {
      localStorage.removeItem("alogin");
      window.location.reload();
    }

    reportWebVitals();
  } catch (error) {
    console.error(error)
  }
})()