import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../../assets/Config';
import { createUpdateCompany, deleteCompany, downloadCompanyDataDoc, getAllCompanies, getCompanyById, restoreCompany, addStockData, getCompanyStockById } from '../../apis/companyManagement/CMApi';
import { useLogin } from '../AdminLoginContext';
import { getCompanyOpenOrders, getInventoryAnalytics } from '../../apis/shareManagement/InventoryAPI';

// create global company management context
export const CMContext = createContext();

export function CMProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();

    const [companyList, setCompanyList] = useState("")
    const [editCompany, setEditCompany] = useState(false)
    const [editCompanyData, setEditCompanyData] = useState({})
    const [showDeletedCompanies, setShowDeletedCompanies] = useState(false)
    const [showRTPage, setShowRTPage] = useState(false)
    const [overview, setOverview] = useState("")
    const [insight, setInsight] = useState("")
    const [annualReport, setAnnualReport] = useState("")
    const [analytics, setAnalytics] = useState("");
    const [analyticsDate, setAnalyticsDate] = useState("");
    const [companyDetail, setCompanyDetail] = useState("");
    const [processedData, setProcessedData] = useState([]);


    // get all companies
    async function getAllCompaniesList(getDeleted) {
        try {
            let response = await getAllCompanies(token, getDeleted);
            if (response && response.status === 200) {
                setCompanyList(response.data)
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setCompanyList("")
            }
        } catch (error) {

        }
    }

    // get inventory data
    async function getInventoryAnalyticsData(companyDetail) {
        try {
            let response = companyDetail ? await getCompanyOpenOrders(token, companyDetail.id, companyDetail.type.toLowerCase()) : await getInventoryAnalytics(token);
            if (response && response.status === 200) {
                setAnalytics(companyDetail ? response.data : response.data.data);
                setAnalyticsDate(response.data.dates);
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setAnalyticsDate("")
            }
        } catch (error) {

        }
    }

    // get company data by id
    async function getCompanyDataById(companyId) {
        try {
            let response = await getCompanyById(token, companyId);
            if (response && response.status === 200) {
                let data = response.data
                setEditCompanyData({
                    ...editCompanyData,
                    ...data
                })
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create or update company
    async function createEditCompany(name, secondaryName, faqTitle, industry, sector, unique_title, isin, rta, status, statusMessage, nsdl, cdsl, display_on_home, featured, tagId, disabletrading, logo, metaData, editCompany, companyId) {
        try {
            let response = await createUpdateCompany(token, name, secondaryName, faqTitle, industry, sector, unique_title, isin, rta, status, statusMessage, nsdl, cdsl, display_on_home, featured, tagId, disabletrading, logo, metaData, editCompany, companyId, overview, insight, annualReport);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // create or update company
    async function addCompanyStock(companyId, face_value, book_value, no_of_shares, eps, sales, industry_pe, dividend, equity, PAT, message) {
        try {
            let response = await addStockData(token, companyId, face_value, book_value, no_of_shares, eps, sales, industry_pe, dividend, equity, PAT, message);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get company company stock data by company id
    async function getCompanyStock(companyId) {
        try {
            let response = await getCompanyStockById(token, companyId);
            if (response && response.status === 200) {
                let data = response.data
                return {
                    success: "success",
                    data: data
                };
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }



    // delete company
    async function deleteCompanyById(companyId) {
        try {
            let response = await deleteCompany(token, companyId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore company
    async function restoreCompanyById(companyId) {
        try {
            let response = await restoreCompany(token, companyId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // download company data csv from server
    async function downloadFile(companyId) {
        try {
            let element = document.getElementById("rmContainer");
            var div = document.createElement('div')
            div.id = "downProgress";
            div.className = 'loader'
            element.appendChild(div)

            let response = await downloadCompanyDataDoc(token, companyId);
            if (response) {
                document.getElementById("downProgress").remove()
            }
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = response.data ? `${companyId}.xlsx` : null; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {
        }
    }

    // export global context
    return (
        <CMContext.Provider
            value={{
                companyList,
                setCompanyList,
                getAllCompaniesList,
                deleteCompanyById,
                restoreCompanyById,
                editCompany,
                setEditCompany,
                editCompanyData,
                setEditCompanyData,
                getCompanyDataById,
                createEditCompany,
                showDeletedCompanies,
                setShowDeletedCompanies,
                addCompanyStock,
                getCompanyStock,
                downloadFile,
                showRTPage,
                setShowRTPage,
                overview,
                setOverview,
                insight,
                setInsight,
                annualReport,
                setAnnualReport,
                getInventoryAnalyticsData,
                setAnalytics,
                analytics,
                companyDetail,
                setCompanyDetail,
                analyticsDate,
                setAnalyticsDate,
                processedData,
                setProcessedData
            }}
        >
            {children}
        </CMContext.Provider>
    );
}

// return company management context
export function useCMContext() {
    return useContext(CMContext);
}