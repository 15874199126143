import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { configs, downloadDoc, formatCurrency, formatNumber, showDocNewTab } from '../../../assets/Config';
import '../../../assets/styles/CustomerProfile.css'
import { getExecutedOrders, getHoldings, getOpenOrders, getPaymentDetails } from '../../../services/apis/customerProfile/CustomerProfileAPI';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useSellOrderContext } from '../../../services/contexts/SellOrdersContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import { getAllLatestRemarkByUser } from '../../../services/apis/CRMApi';
import { lendboxGetDetailByUserId } from '../../../services/apis/LendboxApi';
import { liquiloansGetDetailByUserId } from '../../../services/apis/LiquiloansApi';


export default function CustomerProfilePage() {

    const { kycRequesterUserComplete, setKycRequesterUserComplete, getKYCCustomerDetail, panDoc, addressDoc, bankDoc, dematDoc, otherDoc, kycRequesterUserDetail, setKycRequesterUserDetail, setShowError } = useKYCContext()
    const { downloadInvoiceByOrderId } = useBuyOrderContext()
    const { downloadDeliveryNoteByOrderId } = useSellOrderContext()
    const { setCurrModule, token, logout, setLoginError, allowedFunc } = useLogin()

    const [curTab, setCurTab] = useState("profile")
    const [orderType, setOrderType] = useState("buy")

    const [holdings, setHoldings] = useState("")
    const [openOrder, setOpenOrder] = useState("")
    const [execOrder, setExecOrder] = useState("")
    const [payment, setPayment] = useState("")
    const [remark, setRemark] = useState("")

    const [netPL, setNetPL] = useState(0)
    const [netChange, setNetChange] = useState(0)
    const [totalInvest, setTotalInvest] = useState(0)
    const [totalCurr, setTotalCurr] = useState(0)

    const [holdingLogModal, setHoldingLogModal] = useState(false)
    const [orderDetail, setOrderDetail] = useState("")

    const [lbKYC, setLBKYC] = useState("")
    const [lbPortfolio, setLBPortfolio] = useState("")
    const [lbInvest, setLBInvest] = useState("")
    const [lbWithdraw, setLBWithdraw] = useState("")

    const [lQKYC, setLQKYC] = useState("")
    const [lQPortfolio, setLQPortfolio] = useState("")
    const [lQInvest, setLQInvest] = useState("")
    const [lQWithdraw, setLQWithdraw] = useState("")


    const history = useHistory()

    let { customerId } = useParams()

    useEffect(() => {
        if (customerId) {
            setKycRequesterUserDetail({ id: customerId })
        }

    }, []);

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
            setCurrModule(configs.modules.custProf)
            try {
                (async () => {
                    let response = await getKYCCustomerDetail("custProf");
                    response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "check-registration")
                    if (response && response.status === 200) {
                        setLBKYC(response.data.registred ? "True" : "False")
                    } else if (response && response.status === 401) {
                        logout()
                        setLoginError(configs.unauthorizedErrorMessage)
                    }

                    response = await getKYCCustomerDetail("custProf");
                    response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "check-registration")
                    if (response && response.status === 200) {
                        setLQKYC(response.data.registred ? "True" : "False")
                    } else if (response && response.status === 401) {
                        logout()
                        setLoginError(configs.unauthorizedErrorMessage)
                    }
                })()
            } catch (error) {

            }
        } else {
            return history.goBack()
        }
    }, [kycRequesterUserDetail])

    // handle modal close
    const handleClose = () => {
        setHoldingLogModal(false)
    }

    // get holdings
    async function getHoldingsByCustId() {
        try {
            setHoldings("")
            let response = await getHoldings(token, kycRequesterUserDetail.id)
            if (response && response.status === 200) {
                setHoldings(response.data)
                computeNet(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get holdings
    async function getOpenOrdersByCustId(orderType) {
        try {
            setOpenOrder("")
            let response = await getOpenOrders(token, kycRequesterUserDetail.id, orderType)
            if (response && response.status === 200) {
                setOpenOrder(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get executed orders
    async function getExecutedOrdersByCustId(orderType) {
        try {
            setExecOrder("")
            let response = await getExecutedOrders(token, kycRequesterUserDetail.id, orderType)
            if (response && response.status === 200) {
                setExecOrder(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get payments
    async function getPaymentsByCustId() {
        try {
            setPayment("")
            let response = await getPaymentDetails(token, kycRequesterUserDetail.id)
            if (response && response.status === 200) {
                setPayment(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get remarks by customer id
    async function getRemarksByCustId() {
        try {
            setRemark("")
            let response = await getAllLatestRemarkByUser(token, kycRequesterUserDetail.id)
            if (response && response.status === 200) {
                setRemark(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get lendbox portfolio
    async function getLendboxPortfolio() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "portfolio")
            if (response && response.status === 200) {
                let data = []
                response.data.data.dashboard.forEach(element => {
                    if (element.currentInvestment || element.interestPerDay || element.totalInterestEarning || element.amountWithdrawn || element.availableToWithdraw) {
                        data.push(element)
                    }
                });
                setLBPortfolio(data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get lendbox invest order
    async function getLendboxInvest() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "paymentOrder")
            if (response && response.status === 200) {
                setLBInvest(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get lendbox withdraw order
    async function getLendboxWithdraw() {
        try {
            setRemark("")
            let response = await lendboxGetDetailByUserId(token, kycRequesterUserDetail.id, "debitOrder")
            if (response && response.status === 200) {
                setLBWithdraw(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get Liquiloans portfolio
    async function getLiquiloansPortfolio() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "investor-dashboard")
            if (response && response.status === 200) {
                if (response.data.data.investment.total || response.data.data.locked_in_amount_bal || response.data.data.current_value || response.data.data.requested_withdrawal || response.data.data.withdrawable_amount) {
                    setLQPortfolio([response.data.data])
                } else {
                    setLQPortfolio([]);
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get Liquiloans invest order
    async function getLiquiloansInvest() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "paymentOrder")
            if (response && response.status === 200) {
                setLQInvest(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }
    // get Liquiloans withdraw order
    async function getLiquiloansWithdraw() {
        try {
            setRemark("")
            let response = await liquiloansGetDetailByUserId(token, kycRequesterUserDetail.id, "debitOrder")
            if (response && response.status === 200) {
                setLQWithdraw(response.data.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }


    // compute net details for holdings
    function computeNet(data) {
        try {
            let percent = 0, tpl = 0, nc = 0, ti = 0, tc = 0, cd = [['Instrument', 'Share Percentage']];
            data.map((element) => {
                ti += parseFloat(parseFloat(isNaN(element.quantity) ? 0 : element.quantity) * parseFloat(isNaN(element.average_cost) ? 0 : element.average_cost))
                tc += parseFloat(isNaN(element.current_value) ? 0 : element.current_value)
                tpl += (parseFloat(isNaN(element.profit_loss) ? 0 : element.profit_loss))
            })
            data.map((element) => {
                cd.push([element.company_name, parseFloat(parseFloat(isNaN(element.current_value) ? 0 : element.current_value) / parseFloat(tc) * 100)])
            })
            nc = parseFloat(parseFloat(tc - ti) / parseFloat(ti) * 100)
            setNetPL(parseFloat(tpl))
            setNetChange(nc.toFixed(2))
            setTotalInvest(ti)
            setTotalCurr(tc)

        } catch (error) {
            console.error(error)
        }
    }

    // customer kyc main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='custProfContainer' className="kyc-container kyc-main">
                    <div className="container-fluid">
                        {kycRequesterUserComplete ? <div className="position-relative px-2 pt-2">

                            <div className="row d-flex align-items-start">
                                <div className="col-8">
                                    <p className="f-14 font-weight-bold text-primary mb-0">{kycRequesterUserComplete.basic_details.name}</p>
                                    <p className="f-12 text-custom-grey mb-0">{kycRequesterUserComplete.email}</p>
                                </div>
                                {/* <div className="col-4 text-right f-14">
                                <a href="#" className="custom-link"><i className="fas fa-pen mr-2"></i>Edit</a>
                            </div> */}
                            </div>
                            <div className="menu-list f-14">
                                <ul className="style-menu profile">
                                    <li><a href="#profile" className={curTab === "profile" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("profile")
                                    }}>Profile</a></li>
                                    <li><a href="#holdings" className={curTab === "holdings" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("holdings")
                                        getHoldingsByCustId()
                                    }}>Holdings</a></li>
                                    <li><a href="#openOrders" className={curTab === "openOrder" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("buy")
                                        setCurTab("openOrder")
                                        getOpenOrdersByCustId("buy")
                                    }}>Open Orders - Buy</a></li>
                                    <li><a href="#executedOrders" className={curTab === "execOrder" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("buy")
                                        setCurTab("execOrder")
                                        getExecutedOrdersByCustId("buy")
                                    }}>Executed Orders - Buy</a></li>
                                    <li><a href="#openOrdersSell" className={curTab === "openOrderSell" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("sell")
                                        setCurTab("openOrderSell")
                                        getOpenOrdersByCustId("sell")
                                    }}>Open Orders - Sell</a></li>
                                    <li><a href="#executedOrdersSell" className={curTab === "execOrderSell" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setOrderType("sell")
                                        setCurTab("execOrderSell")
                                        getExecutedOrdersByCustId("sell")
                                    }}>Executed Orders - Sell</a></li>
                                    <li><a href="#payment" className={curTab === "payment" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("payment")
                                        getPaymentsByCustId()
                                    }}>Online Payments</a></li>
                                    <li><a href="#payment" className={curTab === "crm" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("crm")
                                        getRemarksByCustId()
                                    }}>CRM</a></li>
                                    <li><a href="#payment" className={curTab === "lbpo" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbpo")
                                        getLendboxPortfolio()
                                    }}>Lendbox - Portfolio</a></li>
                                    <li><a href="#payment" className={curTab === "lbio" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbio")
                                        getLendboxInvest()
                                    }}>Lendbox - Investment Order</a></li>
                                    <li><a href="#payment" className={curTab === "lbwo" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lbwo")
                                        getLendboxWithdraw()
                                    }}>Lendbox - Withdraw Order</a></li>
                                    <li><a href="#payment" className={curTab === "lqpo" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqpo")
                                        getLiquiloansPortfolio()
                                    }}>Liquiloans - Portfolio</a></li>
                                    <li><a href="#payment" className={curTab === "lqio" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqio")
                                        getLiquiloansInvest()
                                    }}>Liquiloans - Investment Order</a></li>
                                    <li><a href="#payment" className={curTab === "lqwo" ? "active-custProf" : null} onClick={(e) => {
                                        e.preventDefault()
                                        setCurTab("lqwo")
                                        getLiquiloansWithdraw()
                                    }}>Liquiloans - Withdraw Order</a></li>
                                </ul>
                            </div>

                            <div className="custProf-main">

                                {/* <!-- profile section --> */}

                                {curTab === "profile" ? <div className="row mt-3 m-0 p-0">
                                    <div className="col-12 f-14 text-custom-grey justify-content-center">
                                        Name : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.name}</span> <br />
                                        Mobile : <a href={"https://wa.me/" + kycRequesterUserComplete.mobile} target="_blank"><span className="font-wight-bold">{kycRequesterUserComplete.mobile ? `+${kycRequesterUserComplete.mobile}` : kycRequesterUserComplete.mobile}</span></a> <br />
                                        PAN : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.pan_number}</span> <br />
                                        Distributor : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.distributor.name ? `${kycRequesterUserComplete.distributor.name} - ${kycRequesterUserComplete.distributor.utm}` : null}</span> <br />
                                        UTM Recorded From : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.distributor.utm_recorded_from}</span> <br />
                                        Address : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.basic_details.address}</span> <br />
                                        Lendbox KYC Status : <span className="font-wight-bold text-custom-greyDark">{lQKYC}</span> <br />
                                        Liqiuiloans KYC Status : <span className="font-wight-bold text-custom-greyDark">{lbKYC}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Demat Details</span> <br />
                                        DP Id : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.demat_details.dp_id}</span> <br />
                                        Client Id : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.demat_details.client_id}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Bank Details</span> <br />
                                        Bank Name : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.bank_name}</span> <br />
                                        IFSC : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.ifsc_code}</span> <br />
                                        Acc. No. : <span className="font-wight-bold text-custom-greyDark">{kycRequesterUserComplete.bank_details.account_number}</span> <br /> <br />
                                        <span className="text-primary f-16 font-wight-bold">Uploaded Documents</span> <br />
                                        <div className="my-3 d-flex">
                                            <div>
                                                <div className="docs-parent">
                                                    Pan Document
                                                    <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.user_documents.pan_card_password}</label></span>
                                                    <div className="docs-box-profile">
                                                        {panDoc.includes("application/pdf") ? <iframe id="custProfpanFrame" height="100%" width="100%"></iframe> : panDoc.includes("image") ? <img width="100%" height="100%" src={panDoc} /> : null}
                                                        {(panDoc.includes("application/pdf") || panDoc.includes("image")) ?
                                                            <div className="kyc-openDoc-prof-container">
                                                                <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                    showDocNewTab("Pan Document", panDoc)
                                                                }}></i>
                                                                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                    downloadDoc(`${kycRequesterUserComplete.basic_details.name} Pan Document`, panDoc)
                                                                }}></i>
                                                            </div>
                                                            : !kycRequesterUserComplete.user_documents.pan_card ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                    </div>
                                                </div>
                                                <div className="docs-parent">
                                                    Address Proof
                                                    <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.user_documents.address_proof_password}</label></span>
                                                    <div className="docs-box-profile">
                                                        {addressDoc.includes("application/pdf") ? <iframe id="custProfaddressFrame" height="100%" width="100%"></iframe> : addressDoc.includes("image") ? <img width="100%" height="100%" src={addressDoc} /> : null}
                                                        {(addressDoc.includes("application/pdf") || addressDoc.includes("image")) ?
                                                            <div className="kyc-openDoc-prof-container">
                                                                <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                    showDocNewTab("Address Document", addressDoc)
                                                                }}></i>
                                                                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                    downloadDoc(`${kycRequesterUserComplete.basic_details.name} Address Document`, addressDoc)
                                                                }}></i>
                                                            </div>
                                                            : !kycRequesterUserComplete.user_documents.address_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                    </div>
                                                </div>
                                                <div className="docs-parent">
                                                    Bank Account Proof
                                                    <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.user_documents.bank_proof_password}</label></span>
                                                    <div className="docs-box-profile">
                                                        {bankDoc.includes("application/pdf") ? <iframe id="custProfbankFrame" height="100%" width="100%"></iframe> : bankDoc.includes("image") ? <img width="100%" height="100%" src={bankDoc} /> : null}
                                                        {(bankDoc.includes("application/pdf") || bankDoc.includes("image")) ?
                                                            <div className="kyc-openDoc-prof-container">
                                                                <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                    showDocNewTab("Bank Document", bankDoc)
                                                                }}></i>
                                                                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                    downloadDoc(`${kycRequesterUserComplete.basic_details.name} Bank Document`, bankDoc)
                                                                }}></i>
                                                            </div>
                                                            : !kycRequesterUserComplete.user_documents.bank_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                    </div>
                                                </div>
                                                <div className="docs-parent">
                                                    Demat Account Proof
                                                    <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.user_documents.demat_proof_password}</label></span>
                                                    <div className="docs-box-profile">
                                                        {dematDoc.includes("application/pdf") ? <iframe id="custProfdematFrame" height="100%" width="100%"></iframe> : dematDoc.includes("image") ? <img width="100%" height="100%" src={dematDoc} /> : null}
                                                        {(dematDoc.includes("application/pdf") || dematDoc.includes("image")) ?
                                                            <div className="kyc-openDoc-prof-container">
                                                                <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                    showDocNewTab("Demat Document", dematDoc)
                                                                }}></i>
                                                                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                    downloadDoc(`${kycRequesterUserComplete.basic_details.name} Demat Document`, dematDoc)
                                                                }}></i>
                                                            </div>
                                                            : !kycRequesterUserComplete.user_documents.demat_proof ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                    </div>
                                                </div>
                                                <div className="docs-parent">
                                                    Other Document
                                                    <span>Password - <label className="text-custom-greyDark">{kycRequesterUserComplete.user_documents.other_document_password}</label></span>
                                                    <div className="docs-box-profile">
                                                        {otherDoc.includes("application/pdf") ? <iframe id="custProfOtherFrame" height="100%" width="100%"></iframe> : otherDoc.includes("image") ? <img width="100%" height="100%" src={otherDoc} /> : null}
                                                        {(otherDoc.includes("application/pdf") || otherDoc.includes("image")) ?
                                                            <div className="kyc-openDoc-prof-container">
                                                                <i className="fa fa-external-link kyc-openDoc-prof curPoint" onClick={() => {
                                                                    showDocNewTab("Other Document", otherDoc)
                                                                }}></i>
                                                                <i className="fa fa-download kyc-openDoc-prof curPoint" onClick={() => {
                                                                    downloadDoc(`${kycRequesterUserComplete.basic_details.name} Other Document`, otherDoc)
                                                                }}></i>
                                                            </div>
                                                            : !kycRequesterUserComplete.user_documents.other_document ? <h5>No Document Uploaded</h5> : <div className="loader"></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div> : null}

                                {/* <!-- holdings section --> */}

                                {curTab === "holdings" ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {holdings && holdings.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Instrument</th>
                                                            <th scope="col" className="border-right-0">Qty</th>
                                                            <th scope="col" className="border-left-0 border-right-0">Avg Cost</th>
                                                            <th scope="col" className="border-left-0">LTP</th>
                                                            <th scope="col" className="border-right-0">Cur. Value</th>
                                                            <th scope="col" className="border-right-0 border-left-0">P&amp;L</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Change</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {holdings.map((element, key) => {
                                                            return (
                                                                <tr key={`holdtab` + key}>
                                                                    <td className="border-left-0">{element.company_name} <b>{element.company_status === "Now Listed" ? "(Now Listed)" : element.disabletrading ? "(Currently Not Traded)" : ""}</b></td>
                                                                    <td className="border-right-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                    <td className="border-right-0 border-left-0">{formatCurrency(parseFloat(element.average_cost))}</td>
                                                                    <td className="border-left-0">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatCurrency(parseFloat(element.ltp))}</td>
                                                                    <td className="border-right-0">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatCurrency(parseFloat(element.current_value))}</td>
                                                                    {parseFloat(element.profit_loss) < 0 ? <td className="text-success border-right-0 border-left-0 text-danger">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatNumber(parseFloat(parseFloat(element.profit_loss)))}</td> : <td className="text-success border-right-0 border-left-0 text-success">{element.company_status === "Now Listed" || element.disabletrading ? "-" : formatNumber(parseFloat(parseFloat(element.profit_loss)))}</td>}
                                                                    {parseFloat(element.profit_loss) < 0 ? <td className="text-success border-right-0 border-left-0 text-danger">{element.company_status === "Now Listed" || element.disabletrading ? "-" : `${formatNumber(parseFloat(element.net_change))}%`}</td> : <td className="text-success border-right-0 border-left-0 text-success">{element.company_status === "Now Listed" || element.disabletrading ? "-" : `${formatNumber(parseFloat(element.net_change))}%`}</td>}

                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row -d-flex align-items-center">
                                                <div className="col-lg-6">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-6">
                                                            <span className="text-custom-grey h6">Total Investment - </span>
                                                            <span className={"text-custom-greyDark sub-desc mr-2"}>{formatCurrency((parseFloat(totalInvest)))}</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="text-custom-grey h6">Current Value - </span>
                                                            <span className={"text-custom-greyDark sub-desc mr-2"}>{formatCurrency((parseFloat(totalCurr)))}</span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="text-custom-grey h6">Net P&amp;L - </span>
                                                            <span className={parseFloat(netPL) < 0 ? "text-custom-greyDark text-danger sub-desc mr-2" : "text-custom-greyDark text-success sub-desc mr-2"}>{formatNumber(parseFloat(netPL))}</span> <br />

                                                        </div>
                                                        <div className="col-6">
                                                            <span className="text-custom-grey h6">Net Change - </span>
                                                            <span className={parseFloat(netChange) < 0 ? "text-custom-greyDark text-danger sub-desc mr-2" : "text-custom-greyDark text-success sub-desc mr-2"}>{formatNumber(parseFloat(netChange))}%</span> <br />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {/* <!-- open orders --> */}

                                {(curTab === "openOrder" || curTab === "openOrderSell") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {openOrder && openOrder.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Instrument</th>
                                                            <th scope="col" className="border-right-0">Date/Time</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Qty</th>
                                                            <th scope="col" className="border-left-0 border-right-0">Price/Share</th>
                                                            <th scope="col" className="border-left-0">Price</th>
                                                            <th scope="col" className="border-left-0">Total TDS</th>
                                                            {curTab === "openOrder" ? <th scope="col" className="border-left-0">Total TCS</th> : null}
                                                            <th scope="col" className="border-left-0">Upmark</th>
                                                            <th scope="col" className="border-left-0">Upmark Final Pay</th>
                                                            <th scope="col" className="border-right-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {openOrder.map((element, key) => {
                                                            return (
                                                                <tr key={`openOrder` + key}>
                                                                    <td className="border-left-0">{element.order_id}</td>
                                                                    <td className="border-left-0">{orderType === "buy" ? <sup className="buycheck sup-pos">Buy</sup> : <sup className="sellcheck sup-pos">Sell</sup>}{element.company_name}{element.bucket_object && element.bucket_object.length > 0 ? <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={async (e) => {
                                                                        e.preventDefault()
                                                                        setOrderDetail(element)
                                                                        setHoldingLogModal(true)
                                                                    }}></i> : null}</td>
                                                                    <td className="border-right-0">{element.order_time}</td>
                                                                    <td className="border-right-0 border-left-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                    <td className="border-left-0 border-right-0"><div className='d-flex'>{formatCurrency(parseFloat(element.price_per_share))}
                                                                        {element.old_order_price && parseFloat(element.old_order_price) !== parseFloat(element.price) ?
                                                                            <div id={`tooltip${element.order_id}`}>
                                                                                <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    document.getElementById(`tooltip${element.order_id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                                                                                }} />
                                                                                <div className='tooltipBox card'>
                                                                                    <div className="priceSlab">
                                                                                        <p className='my-1'><b>Order Rate Update</b></p>
                                                                                        <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            document.getElementById(`tooltip${element.order_id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                                                                        }}><b>X</b></p>
                                                                                    </div>
                                                                                    <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(element.price_per_share))}</div>
                                                                                    <div className='m-0'>Old Rate: {formatCurrency(parseFloat(element.old_order_price / element.quantity))}</div>
                                                                                </div>``
                                                                            </div> : null}
                                                                    </div></td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.price))}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.total_tds))}</td>
                                                                    {curTab === "openOrder" ? <td className="border-left-0">{formatCurrency(parseFloat(element.total_tcs))}</td> : null}
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                    <td className="border-right-0 text-capitalize">{element.statusMessage}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Open Orders</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {/* <!-- executed orders --> */}

                                {(curTab === "execOrder" || curTab === "execOrderSell") ?

                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {execOrder && execOrder.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Instrument</th>
                                                            <th scope="col" className="border-right-0">Date/Time</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Qty</th>
                                                            <th scope="col" className="border-left-0 border-right-0">Price/Share</th>
                                                            <th scope="col" className="border-left-0">Price</th>
                                                            <th scope="col" className="border-left-0">Total TDS</th>
                                                            {curTab === "execOrder" ? <th scope="col" className="border-left-0">Total TCS</th> : null}
                                                            <th scope="col" className="border-left-0">Upmark</th>
                                                            <th scope="col" className="border-left-0">Upmark Final Pay</th>
                                                            <th scope="col" className="border-right-0">Status</th>
                                                            <th scope="col" className="border-right-0 border-left-0">{orderType === "buy" ? "Invoice" : "Delivery Note"}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {execOrder.map((element, key) => {
                                                            return (
                                                                <tr key={`execOrder` + key}>
                                                                    <td className="border-left-0">{element.order_id}</td>
                                                                    <td className="border-left-0">{orderType === "buy" ? <sup className="buycheck sup-pos">Buy</sup> : <sup className="sellcheck sup-pos">Sell</sup>}{element.company_name}{element.bucket_object && element.bucket_object.length > 0 ? <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={async (e) => {
                                                                        e.preventDefault()
                                                                        setOrderDetail(element)
                                                                        setHoldingLogModal(true)
                                                                    }}></i> : null}</td>
                                                                    <td className="border-right-0">{element.order_time}</td>
                                                                    <td className="border-right-0 border-left-0">{formatNumber(parseInt(element.quantity))}</td>
                                                                    <td className="border-left-0 border-right-0">{formatCurrency(parseFloat(element.price_per_share))}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.price))}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.total_tds))}</td>
                                                                    {curTab === "execOrder" ? <td className="border-left-0">{formatCurrency(parseFloat(element.total_tcs))}</td> : null}
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.upmark))}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.upmark_final_pay))}</td>
                                                                    <td className="border-right-0 text-capitalize">{element.statusMessage}</td>
                                                                    <td className="border-right-0 border-left-0">
                                                                        {element.status.toLowerCase() === "completed" ? <a href="#" className="custom-link text-primary" onClick={(e) => {
                                                                            e.preventDefault()
                                                                            if (orderType === "buy") {
                                                                                downloadInvoiceByOrderId(element.order_id)
                                                                            } else if (orderType === "sell") {
                                                                                downloadDeliveryNoteByOrderId(element.order_id)
                                                                            }
                                                                        }}>Download</a> : null}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Executed Orders</p>}
                                            </div>
                                        </div>

                                    </div>


                                    : null}

                                {/* <!-- payment section --> */}

                                {curTab === "payment" ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {payment && payment.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Instrument</th>
                                                            <th scope="col" className="border-right-0">Date/Time</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Transaction ID</th>
                                                            <th scope="col" className="border-left-0">Amount</th>
                                                            <th scope="col" className="border-right-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {payment.map((element, key) => {
                                                            return (
                                                                <tr key={`payment` + key}>
                                                                    <td className="border-left-0">{element.order_id}</td>
                                                                    <td className="border-left-0"><sup className="buycheck sup-pos">Buy</sup>{element.company_name}</td>
                                                                    <td className="border-right-0">{element.trans_date}</td>
                                                                    <td className="border-right-0 border-left-0">{element.tracking_id}</td>
                                                                    <td className="border-left-0">{formatCurrency(parseFloat(element.amount))}</td>

                                                                    <td className="border-right-0 text-capitalize">{element.order_status}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Payment Details found</p>}
                                            </div>
                                        </div>

                                    </div>
                                    : null}


                                {/* <!-- CRM section --> */}

                                {curTab === "crm" ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {remark && remark.length > 0 ?
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="border-left-0">Relationship Manager</th>
                                                                <th scope="col" className="border-left-0">Call Date</th>
                                                                <th scope="col" className="border-left-0">Remarks</th>
                                                                <th scope="col" className="border-left-0">Investable Surplus</th>
                                                                <th scope="col" className="border-right-0">Interested in</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {remark.map((element, key) => {
                                                                return (
                                                                    <tr key={`remark` + key}>
                                                                        <td className="border-left-0">{element.relationship_manager.name}</td>
                                                                        <td className="border-left-0">{element.last_call_date}</td>
                                                                        <td className="border-left-0">{element.call_remark}</td>
                                                                        <td className="border-left-0">{element.investable_surplus}</td>
                                                                        <td className="border-right-0">{element.interested_in}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    : <p>No Remark Details found</p>}

                                            </div>
                                        </div>

                                    </div>
                                    : null}

                                {/* <!-- lendbox portfolio --> */}
                                {(curTab === "lbpo") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lbPortfolio && lbPortfolio.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Investment Type</th>
                                                            <th scope="col" className="text-right">Current Investment</th>
                                                            <th scope="col" className="text-right">Interest per day</th>
                                                            <th scope="col" className="text-right">Total Interest Earning</th>
                                                            <th scope="col" className="text-right">Amount Withdrawn</th>
                                                            <th scope="col" className="text-right">Available to Withdraw</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lbPortfolio.map((element, key) => {
                                                            return (
                                                                <tr key={`lbIPortfolio` + key} >
                                                                    <td className="border-left-0">{element.type}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.currentInvestment))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.interestPerDay))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.totalInterestEarning))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.amountWithdrawn))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.availableToWithdraw))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Lendbox Portfolio data found</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {/* <!-- lendbox invvest order --> */}
                                {(curTab === "lbio") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lbInvest && lbInvest.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Date</th>
                                                            <th scope="col" className="border-left-0" style={{ width: "50%" }}>Add On</th>
                                                            <th scope="col" className='text-right'>Amount</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lbInvest.map((element, key) => {
                                                            return (
                                                                <tr key={`lbInvest` + key} >
                                                                    <td scope="col" className="border-left-0">{element.id}</td>
                                                                    <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                    <td scope="col" className="border-left-0">{element.add_on}</td>
                                                                    <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                    <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Lendbox Investment Orders</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}
                                {/* <!-- lendbox withdraw order --> */}
                                {(curTab === "lbwo") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lbWithdraw && lbWithdraw.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Date</th>
                                                            <th scope="col" className="border-left-0" style={{ width: "50%" }}>Add On</th>
                                                            <th scope="col" className='text-right'>Amount</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lbWithdraw.map((element, key) => {
                                                            return (
                                                                <tr key={`lbWithdraw` + key} >
                                                                    <td scope="col" className="border-left-0">{element.id}</td>
                                                                    <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                    <td scope="col" className="border-left-0">{element.add_on}</td>
                                                                    <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                    <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Lendbox Withdraw Orders</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {/* <!-- Liquiloans portfolio --> */}
                                {(curTab === "lqpo") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lQPortfolio && lQPortfolio.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-right">Total Investment</th>
                                                            <th scope="col" className="text-right">Locked In Amount</th>
                                                            <th scope="col" className="text-right">Current Investment</th>
                                                            <th scope="col" className="text-right">Requested Withdrawal</th>
                                                            <th scope="col" className="text-right">Available to Withdraw</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lQPortfolio.map((element, key) => {
                                                            return (
                                                                <tr key={`lQIPortfolio` + key} >
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.investment.total))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.locked_in_amount_bal))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.current_value))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.requested_withdrawal))}</td>
                                                                    <td className="text-right">{formatCurrency(parseFloat(element.withdrawable_amount))}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Liquiloans Portfolio data found</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}

                                {/* <!-- Liquiloans invvest order --> */}
                                {(curTab === "lqio") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lQInvest && lQInvest.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Date</th>
                                                            <th scope="col" className="border-left-0">Scheme Id</th>
                                                            <th scope="col" className='text-right'>Amount</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lQInvest.map((element, key) => {
                                                            return (
                                                                <tr key={`lQInvest` + key} >
                                                                    <td scope="col" className="border-left-0">{element.id}</td>
                                                                    <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                    <td scope="col" className="border-left-0">{element.scheme_id}</td>
                                                                    <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                    <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Liquiloans Investment Orders</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}
                                {/* <!-- Liquiloans withdraw order --> */}
                                {(curTab === "lqwo") ?
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <div className="table-responsive">
                                                {lQWithdraw && lQWithdraw.length > 0 ? <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="border-left-0">Order Id</th>
                                                            <th scope="col" className="border-left-0">Date</th>
                                                            <th scope="col" className='text-right'>Amount</th>
                                                            <th scope="col" className="border-right-0 border-left-0">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lQWithdraw.map((element, key) => {
                                                            return (
                                                                <tr key={`lQWithdraw` + key} >
                                                                    <td scope="col" className="border-left-0">{element.id}</td>
                                                                    <td scope="col" className="border-left-0">{element.date ? element.date.split(" ")[0] : null}</td>
                                                                    <td scope="col" className='text-right'>{formatCurrency(parseFloat(element.amount))}</td>
                                                                    <td scope="col" className="border-right-0 border-left-0">{element.status}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table> : <p>No Liquiloans Withdraw Orders</p>}
                                            </div>
                                        </div>

                                    </div>

                                    : null}
                            </div>
                            {/* ----------------------------basket order log--------------------------  */}
                            <Modal open={holdingLogModal} onClose={handleClose}>
                                <div className="modal-fade modalPosition" id="orderDetail" tabIndex="-1" role="dialog" aria-labelledby="addHoldingTitle" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header border-0">
                                                <p className="modal-title text-primary subheading2 m-0 p-0 font-weight-bold" id="exampleModalLongTitle">Order {orderDetail.order_id} Breakup</p>
                                                <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                                    e.preventDefault()
                                                    handleClose()
                                                }}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <p><span className="text-custom-grey">Instrument -</span> {orderDetail.company_name}</p>
                                                <div className="table-responsive">
                                                    {orderDetail && orderDetail.bucket_object.length > 0 ? <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" >ISIN</th>
                                                                <th scope="col" >Asset Name</th>
                                                                <th scope="col" className='text-right' >Quantity</th>
                                                                <th scope="col" className='text-right' >Rate</th>
                                                                <th scope="col" className='text-right' >Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderDetail.bucket_object.map((element, key) => {
                                                                return (
                                                                    <tr key={`holdtab` + key} className="subContent">
                                                                        <td >{element.isin}</td>
                                                                        <td >{element.asset_name}</td>
                                                                        <td className='text-right'>{formatNumber(parseInt(element.quantity))}</td>
                                                                        <td className='text-right'>{formatCurrency(parseFloat(element.price / element.quantity))}</td>
                                                                        <td className='text-right'>{formatCurrency(parseFloat(element.price))}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr className="subContent">
                                                                <td colSpan={4} className='text-right font-weight-bold'>Total Amount</td>
                                                                <td className='text-right'>{formatCurrency(parseFloat(orderDetail.price))}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table> : <div className="loader"></div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                            : <div className="loader"></div>}
                    </div>
                </div>
            </div >
        </>
    )
}