import React from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/LeftBar.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';

export default function LeftBarPage() {

    const { setKycRequesterUserDetail } = useKYCContext()
    const { allowedFunc, currModule, setCurrModule } = useLogin()
    const history = useHistory()

    try {
        window.onload = () => {
            if (document.getElementById("sidebarCompanyTableContainer")) {
                document.getElementById("sidebarCompanyTableContainer").style.height = document.getElementById("bottomContainer").offsetTop - (document.getElementById("sidebarCompanyTableContainer").offsetTop + document.getElementById("bottomContainer").offsetHeight) + "px";
            }
        }
        window.addEventListener('resize', () => {
            if (document.getElementById("sidebarCompanyTableContainer")) {
                document.getElementById("sidebarCompanyTableContainer").style.height = document.getElementById("bottomContainer").offsetTop - (document.getElementById("sidebarCompanyTableContainer").offsetTop + document.getElementById("bottomContainer").offsetHeight) + "px";
            }
        });
    } catch (error) {

    }

    return (
        <div id="leftBar" className="leftbar-container d-lg-block d-none">
            <div className="row p-0 m-0">
                {(allowedFunc.includes(configs.functionList['kyc:read_user_kyc_info']) || allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status']) || allowedFunc.includes(configs.functionList['user_pro:user_pro']) || allowedFunc.includes(configs.functionList['user_management:load_user_details']) || allowedFunc.includes(configs.functionList['user_management:load_user_actions'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Customer Management</p>
                        {(allowedFunc.includes(configs.functionList['kyc:read_user_kyc_info']) || allowedFunc.includes(configs.functionList['kyc:update_user_kyc_status'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.kyc ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.kyc)
                                    setKycRequesterUserDetail("")
                                    history.push("/kyc")
                                }}><i className="fas fa-users-cog"></i>&nbsp; KYC Requests</a>
                            </div>
                            : null}
                        {allowedFunc.includes(configs.functionList['user_management:load_user_details']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.custStat ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.custStat)
                                    setKycRequesterUserDetail("")
                                    history.push("/customerStatistics")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Customer Statistics</a>
                            </div>
                            : null}
                        {allowedFunc.includes(configs.functionList['user_management:load_user_actions']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.custActions ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.custActions)
                                    setKycRequesterUserDetail("")
                                    history.push("/customerActions")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Customer Actions</a>
                            </div>
                            : null}
                        {allowedFunc.includes(configs.functionList['user_pro:user_pro']) ?
                            <>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custProLead ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custProLead)
                                        setKycRequesterUserDetail("")
                                        history.push("/proLeads")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Pro Leads</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custProPayment ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custProPayment)
                                        setKycRequesterUserDetail("")
                                        history.push("/proPaymentDetail")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Pro Payment Details</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custProReport ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custProReport)
                                        setKycRequesterUserDetail("")
                                        history.push("/proUserReport")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Pro User Report</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custProPricing ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custProPricing)
                                        setKycRequesterUserDetail("")
                                        history.push("/proPlans")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Pro Plan pricing</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custXclusiveLead ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custXclusiveLead)
                                        setKycRequesterUserDetail("")
                                        history.push("/xclusiveLeads")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Xclusive Lead Details</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custXclusivePayment ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custXclusivePayment)
                                        setKycRequesterUserDetail("")
                                        history.push("/xclusivePaymentDetail")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Xclusive Payment Details</a>
                                </div>
                            </>
                            : null}
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['crm:add_insight']) || allowedFunc.includes(configs.functionList['crm:get_insights'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">CRM Management</p>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.CRM ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.CRM)
                                setKycRequesterUserDetail("")
                                history.push("/crm")
                            }}><i className="fas fa-users-cog"></i>&nbsp; User Insights</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.NoUser ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.NoUser)
                                setKycRequesterUserDetail("")
                                history.push("/nonuserinsight")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Non User Insights</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.OrderInsight ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.OrderInsight)
                                setKycRequesterUserDetail("")
                                history.push("/orderinsight")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Order Insights</a>
                        </div>
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['lendbox_data:lendbox_data'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Lendbox Management</p>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lbkyc ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lbkyc)
                                setKycRequesterUserDetail("")
                                history.push("/lendbox/users")
                            }}><i className="fas fa-users-cog"></i>&nbsp; KYC Requests</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lbinvest ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lbinvest)
                                setKycRequesterUserDetail("")
                                history.push("/lendbox/investment")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Investment Orders</a>
                        </div>
                        {allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.lbwithdraw ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.lbwithdraw)
                                    setKycRequesterUserDetail("")
                                    history.push("/lendbox/withdraw")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Withdraw Orders</a>
                            </div>
                            : null}
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lbCase ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lbCase)
                                setKycRequesterUserDetail("")
                                history.push("/lendbox/caseTracking")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Case Tracking</a>
                        </div>
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['liquiloans_data:liquiloans_data'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Liquiloans Management</p>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lqkyc ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lqkyc)
                                setKycRequesterUserDetail("")
                                history.push("/liquiloan/users")
                            }}><i className="fas fa-users-cog"></i>&nbsp; KYC Requests</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lqinvest ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lqinvest)
                                setKycRequesterUserDetail("")
                                history.push("/liquiloan/investment")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Investment Orders</a>
                        </div>
                        {allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.lqwithdraw ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.lqwithdraw)
                                    setKycRequesterUserDetail("")
                                    history.push("/liquiloan/withdraw")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Withdraw Orders</a>
                            </div>
                            : null}
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.lqCase ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.lqCase)
                                setKycRequesterUserDetail("")
                                history.push("/liquiloan/caseTracking")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Case Tracking</a>
                        </div>
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['kyc:read_distributor_kyc_info']) || allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) || allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Distributor Management</p>
                        {(allowedFunc.includes(configs.functionList['kyc:read_distributor_kyc_info']) || allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.distKYC ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.distKYC)
                                    setKycRequesterUserDetail("")
                                    history.push("/distributor")
                                }}><i className="fas fa-users-cog"></i>&nbsp; KYC Requests</a>
                            </div>
                            : null}
                        {allowedFunc.includes(configs.functionList['distributor_management:load_distributor_details']) ?
                            <>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.distFormLead ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.distFormLead)
                                        setKycRequesterUserDetail("")
                                        history.push("/distributorFormLeads")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Form Leads</a>
                                </div>

                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.distStat ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.distStat)
                                        setKycRequesterUserDetail("")
                                        history.push("/distributorStatistics")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Drop Off</a>
                                </div>

                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.distComm ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.distComm)
                                        history.push("/distributorcommission")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Commission</a>
                                </div>

                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.distCommLed ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.distCommLed)
                                        history.push("/distributorcommissionledger")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Commission Ledger</a>
                                </div>

                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.distPayout ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.distPayout)
                                        history.push("/distributorpayout")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Payout</a>
                                </div>
                            </>
                            : null}
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['role_management:role_management'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Role Management</p>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.rm ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.rm)
                                history.push("/roleManagement")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Manage Roles</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.am ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.am)
                                history.push("/adminManagement")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Manage Admins</a>
                        </div>
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['notification_management:notification_management']) || allowedFunc.includes(configs.functionList['broadcast:whatsapp']) || allowedFunc.includes(configs.functionList['whatsapp:whatsapp'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Notification Management</p>
                        {allowedFunc.includes(configs.functionList['notification_management:notification_management']) ?
                            <>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.mn ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.mn)
                                        history.push("/notificationManagement")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Manage Notifications</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.mnt ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.mnt)
                                        history.push("/notificationTemplateManagement")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Manage Notification Templates</a>
                                </div>
                            </>
                            : null}
                        {allowedFunc.includes(configs.functionList['broadcast:whatsapp']) || allowedFunc.includes(configs.functionList["whatsapp:whatsapp"]) ?
                            <>
                                {allowedFunc.includes(configs.functionList['broadcast:whatsapp']) ?
                                    <div className="sub-menu d-flex flex-column curPoint">
                                        <a className={currModule === configs.modules.whatsappBroadcast ? "my-1 active" : ""} onClick={(e) => {
                                            setCurrModule(configs.modules.whatsappBroadcast)
                                            history.push("/whatsappBroadcast")
                                        }}><i className="fas fa-users-cog"></i>&nbsp; WhatsApp Broadcast</a>
                                    </div>
                                    : null}
                                {allowedFunc.includes(configs.functionList['whatsapp:whatsapp']) ?
                                    <div className="sub-menu d-flex flex-column curPoint">
                                        <a className={currModule === configs.modules.whatsappMessage ? "my-1 active" : ""} onClick={(e) => {
                                            setCurrModule(configs.modules.whatsappMessage)
                                            history.push("/whatsappMessages")
                                        }}><i className="fas fa-users-cog"></i>&nbsp; WhatsApp Responses</a>
                                    </div>
                                    : null}
                            </>
                            : null}
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['buy_module:export_inventory']) || allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) || allowedFunc.includes(configs.functionList['buy_module:load_all_buy_orders']) || (allowedFunc.includes(configs.functionList['buy_module:buy_order_manual_punching']) || allowedFunc.includes(configs.functionList['sell_module:sell_order_manual_punching'])) || allowedFunc.includes(configs.functionList['sell_module:load_all_sell_orders']) || allowedFunc.includes(configs.functionList['order_mandate:order_mandate']) || allowedFunc.includes(configs.functionList['company_parser:update_company_data']) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Share Management</p>
                        {(allowedFunc.includes(configs.functionList['buy_module:export_inventory']) || allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.smInventory ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.smInventory)
                                    history.push("/shareManagement/inventory")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Inventory</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['buy_module:update_inventory']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.manualInventoryUpdate ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.manualInventoryUpdate)
                                    history.push("/shareManagement/inventoryupdate")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manual Inventory Update</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['company_parser:update_company_data']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.depository ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.depository)
                                    history.push("/shareManagement/depository")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Depository File Update</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['buy_module:load_all_buy_orders']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.smBuyOrder ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.smBuyOrder)
                                    history.push("/shareManagement/buyOrders")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Buy Orders</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['buy_module:load_all_buy_orders']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.smCCAvenueHistory ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.smCCAvenueHistory)
                                    history.push("/shareManagement/ccavenuehistory")
                                }}><i className="fas fa-users-cog"></i>&nbsp; CCAvenue Transaction History</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['buy_module:buy_order_manual_punching']) || allowedFunc.includes(configs.functionList['sell_module:sell_order_manual_punching'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.manualPunch ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.manualPunch)
                                    history.push("/shareManagement/manualPunch")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manual Order Punch</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['order_mandate:order_mandate']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.mandate ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.mandate)
                                    history.push("/shareManagement/mandate")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Mandate Management</a>
                            </div>
                            : null}

                        {allowedFunc.includes(configs.functionList['sell_module:load_all_sell_orders']) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.smSellOrder ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.smSellOrder)
                                    history.push("/shareManagement/sellOrders")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Sell Orders</a>
                            </div>
                            : null}
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList["bond_management:view_asset_data"])) || (allowedFunc.includes(configs.functionList['mld_management:view_asset_data'])) || (allowedFunc.includes(configs.functionList['gsec_management:view_asset_data'])) || (allowedFunc.includes(configs.functionList['startup_management:view_asset_data'])) || (allowedFunc.includes(configs.functionList['bucket_management:view_asset_data'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Asset Management</p>
                        {(allowedFunc.includes(configs.functionList["bond_management:view_asset_data"])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.bondMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.bondMgmt)
                                    history.push("/assetManagement/bond")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage Bonds</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['mld_management:view_asset_data'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.mldMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.mldMgmt)
                                    history.push("/assetManagement/mld")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage MLDs</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['gsec_management:view_asset_data'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.gsecMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.gsecMgmt)
                                    history.push("/assetManagement/gsec")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage Government Securities</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['startup_management:view_asset_data'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.startupMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.startupMgmt)
                                    history.push("/assetManagement/startup")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage Startups</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['bucket_management:view_asset_data'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.bucketMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.bucketMgmt)
                                    history.push("/assetManagement/bucket")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage Pre-IPO Bucket</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['bucket_management:view_asset_data'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.mixBucketMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.mixBucketMgmt)
                                    history.push("/assetManagement/mixbucket")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Manage Mix Bucket</a>
                            </div>
                            : null}
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['company_management:view_company'])) || (allowedFunc.includes(configs.functionList['company_management:news_management'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Company Management</p>
                        {(allowedFunc.includes(configs.functionList['company_management:view_company'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.cm ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.cm)
                                    history.push("/companyManagement/companyDetail")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Company Detail</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['company_management:news_management'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.newsManage ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.newsManage)
                                    history.push("/companyManagement/newsDetail")
                                }}><i className="fas fa-users-cog"></i>&nbsp; News Detail</a>
                            </div>
                            : null}
                    </div>
                    : null}

                {allowedFunc.includes(configs.functionList['referral_management:referral_management']) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Referral Management</p>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.referralMaster ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.referralMaster)
                                history.push("/referralManagemnt")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Customer Referral</a>
                        </div>
                        <div className="sub-menu d-flex flex-column curPoint">
                            <a className={currModule === configs.modules.distreferralMaster ? "my-1 active" : ""} onClick={(e) => {
                                setCurrModule(configs.modules.distreferralMaster)
                                history.push("/distributorReferralManagemnt")
                            }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Referral</a>
                        </div>
                    </div>
                    : null}

                {(allowedFunc.includes(configs.functionList['remark_management:remark_management']) || allowedFunc.includes(configs.functionList['social_media:social_media'])) || (allowedFunc.includes(configs.functionList['coupon_management:coupon_management'])) || (allowedFunc.includes(configs.functionList["dashboard_management:manage_dashboard"]) || allowedFunc.includes(configs.functionList['career_page_management:career_page_management'])) ?
                    <div className="menu">
                        <p className="text-custom-grey mb-0 pb-2">Miscelleanous</p>
                        {(allowedFunc.includes(configs.functionList['coupon_management:coupon_management'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.couponMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.couponMgmt)
                                    history.push("/couponManagemnt")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Coupon Management</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['social_media:social_media'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.socialMediaImage ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.socialMediaImage)
                                    history.push("/generateSocialMediaImage")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Social Media Image</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['remark_management:remark_management'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.remarkMaster ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.remarkMaster)
                                    history.push("/remarkManagemnt")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Remark Management</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList["dashboard_management:manage_dashboard"])) ?
                            <>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custDashMng ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custDashMng)
                                        history.push("/customerDashboardManagement")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Customer Dashboard Management</a>
                                </div>
                                <div className="sub-menu d-flex flex-column curPoint">
                                    <a className={currModule === configs.modules.custDashHighMng ? "my-1 active" : ""} onClick={(e) => {
                                        setCurrModule(configs.modules.custDashHighMng)
                                        history.push("/customerDashboardHighlightManagement")
                                    }}><i className="fas fa-users-cog"></i>&nbsp; Customer Dashboard Highlight Management</a>
                                </div>
                            </>
                            : null}

                        {(allowedFunc.includes(configs.functionList["dashboard_management:manage_dashboard"])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.distDashMng ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.distDashMng)
                                    history.push("/distributorDashboardManagement")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Distributor Dashboard Management</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList["dashboard_management:manage_dashboard"])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.promoMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.promoMgmt)
                                    history.push("/promoManagement")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Promotional Updates Management</a>
                            </div>
                            : null}

                        {(allowedFunc.includes(configs.functionList['career_page_management:career_page_management'])) ?
                            <div className="sub-menu d-flex flex-column curPoint">
                                <a className={currModule === configs.modules.careerMgmt ? "my-1 active" : ""} onClick={(e) => {
                                    setCurrModule(configs.modules.careerMgmt)
                                    history.push("/careerManagemnt")
                                }}><i className="fas fa-users-cog"></i>&nbsp; Career Management</a>
                            </div>
                            : null}
                    </div>
                    : null}
            </div>
        </div>
    )
}