import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';
import { uploadCSVDoc, downloadCSVDoc, uploadDepositorySheet } from '../../../services/apis/shareManagement/InventoryAPI';
import ToastPage from '../../includes/ToastPage';

export default function DUpdateInventoryPage() {

    const { setShowSuccess, token, logout, setLoginError, setCurrModule, allowedFunc } = useLogin()
    const { setProcessedData } = useCMContext();

    const [file, setFile] = useState("")
    const [fileType, setFileType] = useState("buy")
    const [depositoryType, setDepositoryType] = useState("NSDL")
    const [error, setError] = useState("")
    const [errorDetail, setErrorDetail] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (!allowedFunc.includes(configs.functionList['company_parser:update_company_data'])) {
            return history.goBack()
        } else {
            setCurrModule(configs.modules.depository)
        }
    })

    const handleClose = () => {
        setShowErrorModal(false);
    };

    // get file contents through file chooser
    function uploadFile(file, dep) {
        try {
            if ((file.type === ".csv" || file.type === "text/csv" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
            } else {
                alert("Only csv files are allowed.")
            }
        } catch (error) {

        }
    }

    // upload csv to server
    async function uploadFileToServer(dep) {
        try {
            if (depositoryType === "CDSL" && fileType === "buy") {
                setError("You can only upload NSDL sheet with Buy Order Type.")
            } else if (file) {
                document.getElementById("uploadFileBtn").classList.toggle("d-none")
                document.getElementById("uploadFileBtnLoader").classList.toggle("d-none")

                let response = await uploadDepositorySheet(token, file, depositoryType, fileType);
                if (response && response.status === 200) {
                    if (response.data.report && response.data.report.error) {
                        setError(response.data.report.error)
                    } else {
                        setShowSuccess(true)
                        setError("")
                        setProcessedData(response.data && response.data.report ? response.data.report : []);
                    }
                    setFile(null)

                } else if (response && response.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage);
                } else if (response && response.status === 406) {
                    setError("Click to view error details.");
                    setErrorDetail(response.data.data);
                }
                else {
                    setError(response.data.error)
                    setFile(null)
                }

                document.getElementById("uploadFileBtn").classList.toggle("d-none")
                document.getElementById("uploadFileBtnLoader").classList.toggle("d-none")
            } else {
                setError("Please select a file to upload.");
            }
        } catch (error) {
            console.error(error);
            setError(error);
        }
    }



    // create role ui code
    return (
        <div id="updateExportInventory" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <form action="">
                <div className="form-group">

                    <label htmlFor="upload_userPAN"><b>Select Sheet/Order Type</b></label>
                    <select className='form-control' value={fileType} onChange={(e) => {
                        setFileType(e.target.value)
                    }}>
                        <option value="buy">Buy</option>
                        <option value="sell">Sell</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="upload_userPAN"><b>Select Depository</b></label>
                    <select className='form-control' value={depositoryType} onChange={(e) => {
                        setDepositoryType(e.target.value)
                    }}>
                        <option value="NSDL">NSDL</option>
                        <option value="CDSL">CDSL</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="upload_userPAN"><b>Select/Upload File</b></label>
                    <div className="file-border rounded py-4" onDragOver={(e) => {
                        e.preventDefault();
                    }} onDrop={(e) => {
                        uploadFile(e.dataTransfer.files[0])
                        e.preventDefault();
                    }}>
                        <i className="fas fa-file-upload"></i>
                        <p className="pb-0 mb-0">
                            Drag your file or &nbsp;
                            <input type="file" id="upload_userPAN" required hidden accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                                uploadFile(e.target.files[0])
                                e.target.value = '';
                            }} />
                            <label htmlFor="upload_userPAN" className="mb-0 pb-0">Click here</label>
                            &nbsp; to choose <br /> Upload: <span className="text-theme">{file ? file.name : ""}</span>
                        </p>
                    </div>
                </div>
                <div className="form-check p-0">
                    {errorDetail ? <p className="text-danger curPoint" id="inventoryError" onClick={(e) => {
                        e.preventDefault()
                        setShowErrorModal(true)
                    }}>{error}</p> : <p className="text-danger" id="inventoryError">{error}</p>}
                </div>
                <div className="text-center row d-flex">
                    <div className='col-12 p-0'>
                        <a id="uploadFileBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            uploadFileToServer()
                        }}><i className="fas fa-upload mr-2"></i>Upload CSV</a>
                        <div className='d-flex justify-content-center'>
                            <div id="uploadFileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                </div>
            </form>

            <div className='row m-0 p-0 mt-5'>
                <button className="btn btn-primary d-md-none" onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("updateExportInventory").classList.toggle('d-none')
                    document.getElementById("inventoryAnalyticsListPage").classList.toggle('d-none')
                    history.push('/shareManagement/depository')
                }}>View Report</button>
            </div>
            <ToastPage />

            {/* --------------------------------------------Error Detail Modal-------------------------------------------------------------------- */}
            <Modal open={showErrorModal} onClose={handleClose}>
                <div className="modal-fade" id="KYCSubmitModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5>Error Detail</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body py-3" style={{ overflowY: "auto", height: "500px" }}>
                                <p className="text-danger">Following errors were found while trying to upload data:</p>
                                <ol>
                                    {Object.keys(errorDetail).map((element, key) => {
                                        if (Array.isArray(errorDetail[element]) && errorDetail[element].length > 0) {
                                            return <li key={`out` + key}>{element}
                                                <ol type='i'>
                                                    {errorDetail[element].map((inner, key) => {
                                                        return <li key={`inner` + key}>{inner}</li>
                                                    })}
                                                </ol>
                                            </li>
                                        } else if (!Array.isArray(errorDetail[element])) {
                                            return <li key={`out` + key}>{element}
                                                <ol type='i'>
                                                    {Object.keys(errorDetail[element]).map((inner, key) => {
                                                        return <li key={`inner` + key}>{inner}
                                                            <ol type='a'>
                                                                {errorDetail[element][inner].map((error, key) => {
                                                                    return <li key={`err` + key}>{error}</li>
                                                                })}
                                                            </ol>
                                                        </li>
                                                    })}
                                                </ol>
                                            </li>
                                        }

                                    })}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}